import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import PrivacyDeletionData from '../Data/PrivacyDeletionData'

const PrivacyDeletionWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0% 8%;
  .DeletionHeading {
    padding: 2% 0% 2% 0%;
    font-size: 2rem;
  }
  .DeletioniconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .ParaSize {
    font-size: 1.2rem;
    line-height: 1.4;
  }
`

export const AppPrivacyDeletion = () => {
  return (
    <PrivacyDeletionWapper>
      {PrivacyDeletionData.map((data, index) => {
        return (
          <div key={index}>
            <ReUseHTwotag Heading={data.Title} HeadingStyle='DeletionHeading' />
            <ReUsePtag para={data.paraOne} paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitOne} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitTwo} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraTwo} paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitThree} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitFour} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitFive} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitSix} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitSeven} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitEight} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitNine} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.paraPonitTen} Icon={data.icon} IconStyle='DeletioniconColor' paraStyle='ParaSize' />
            <ReUsePtag
              para={data.paraPonitEleven}
              Icon={data.icon}
              IconStyle='DeletioniconColor'
              paraStyle='ParaSize'
            />
          </div>
        )
      })}
    </PrivacyDeletionWapper>
  )
}
