import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import PrivacyPersonalData from '../Data/PrivacyPersonalData'

const PrivacyPersonalWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0% 8% 0% 8%;
  .personalHeading {
    padding: 2% 0% 2% 0%;
    font-size: 2rem;
  }
  .PersonaliconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .ParaSize {
    font-size: 1.2rem;
    line-height: 1.4;
  }
`

export const AppPrivacyPersonal = () => {
  return (
    <PrivacyPersonalWapper>
      {PrivacyPersonalData.map((data, index) => {
        return (
          <div key={index}>
            <ReUsePtag para={data.UpperPara} paraStyle='ParaSize' />
            <ReUseHTwotag Heading={data.Title} HeadingStyle='personalHeading' />
            <ReUsePtag para={data.LowerParaOne} paraStyle='ParaSize' />
            <ReUsePtag para={data.ParaPonitOne} Icon={data.icon} IconStyle='PersonaliconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.ParaPonitTwo} Icon={data.icon} IconStyle='PersonaliconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.ParaPonitThree} Icon={data.icon} IconStyle='PersonaliconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.ParaPonitFour} Icon={data.icon} IconStyle='PersonaliconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.ParaPonitFive} Icon={data.icon} IconStyle='PersonaliconColor' paraStyle='ParaSize' />
            <ReUsePtag para={data.LowerParaTwo} paraStyle='ParaSize' />
          </div>
        )
      })}
    </PrivacyPersonalWapper>
  )
}
