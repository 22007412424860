import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import PrivacyTrackingData from '../Data/PrivacyTrackingData.json'

const PrivacyTrackingWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0% 8%;
  .TrackingHeading {
    padding: 2% 0% 2% 0%;
    font-size: 2rem;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .ParaSize {
    font-size: 1.2rem;
    line-height: 1.4;
  }
`

export const AppPrivacyTracking = () => {
  return (
    <PrivacyTrackingWapper>
      {PrivacyTrackingData.map((data, index) => {
        return (
          <div key={index}>
            <ReUseHTwotag Heading={data.Title} HeadingStyle='TrackingHeading' />
            <ReUsePtag para={data.ParaOne} paraStyle='ParaSize' />
            <ReUsePtag para={data.ParaTwo} paraStyle='ParaSize' />
            <ReUsePtag para={data.paraThree} paraStyle='ParaSize' />
          </div>
        )
      })}
    </PrivacyTrackingWapper>
  )
}
