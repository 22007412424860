import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
// import { ReuseImgTag } from './ReuseImgTag'
import PrivacyBannerData from '../Data/PrivacyBannerData.json'

const PrivacyBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 1%;
  padding-top: 100px;
  .PrivacyBannerImageStyle {
    display: flex;
    justify-content: left;
    padding-top: 3%;
    @media (max-width: 1321px) {
      padding: 10%;
    }
  }
  .PrivacyBannerImage {
    height: auto;
    width: 18%;
    @media (max-width: 1321px) {
      width: 100%;
    }
  }
  .PrivacyBannerHeading {
    font-size: 2rem;
  }
  .HeadingSection {
    padding: 1% 7% 0% 7%;
  }
  .ControllerSection {
    margin: auto;
    @media (max-width: 1321px) {
      flex-wrap: wrap;
    }
  }
`

export const AppPrivacyBanner = () => {
  return (
    <PrivacyBannerWapper>
      {PrivacyBannerData.map((data, index) => {
        return (
          <div key={index}>
            <div className="ControllerSection">
              {/* <ReuseImgTag ImagePath={data.ImagePath} ImageStyle='PrivacyBannerImage' /> */}
              <div className="HeadingSection">
                <ReUseHOneTag Heading={data.Title} HeadingStyle="PrivacyBannerHeading" />
              </div>
            </div>
          </div>
        )
      })}
    </PrivacyBannerWapper>
  )
}
