import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import PrivacyChildrenData from '../Data/PrivacyChildrenData.json'

const PrivacyChildrenWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0% 8% 4% 8%;
  .ChildrenHeading {
    padding: 2% 0% 2% 0%;
    font-size: 2rem;
  }
  .ParaSize {
    font-size: 1.2rem;
    line-height: 1.4;
  }
`

export const AppPrivacyChildren = () => {
  return (
    <PrivacyChildrenWapper>
      {PrivacyChildrenData.map((data, index) => {
        return (
          <div key={index}>
            <ReUseHTwotag Heading={data.Title} HeadingStyle="ChildrenHeading" />
            <ReUsePtag para={data.paraOne} paraStyle="ParaSize" />
            <ReUsePtag para={data.paraTwo} paraStyle="ParaSize" />
            <ReUsePtag para={data.paraThree} paraStyle="ParaSize" />
            <ReUsePtag para={data.paraFour} paraStyle="ParaSize" />
            <ReUsePtag para={data.paraFive} paraStyle="ParaSize" />
            <ReUsePtag para={data.paraSix} paraStyle="ParaSize" />
          </div>
        )
      })}
    </PrivacyChildrenWapper>
  )
}
