import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Title: 'Collection of your Personal Information',
    UpperPara:
      'Protecting your private information is our priority.  This Statement of Privacy applies to raindrop.com and Raindrop Systems, Inc. and governs data collection and usage.  For the purposes of this Privacy Policy, unless otherwise noted, all references to Raindrop Systems, Inc. include raindrop.com and Raindrop.  The Raindrop website is a Cloud based SaaS Application site.  By using the Raindrop website, you consent to the data practices described in this statement.',
    LowerParaOne:
      'In order to better provide you with products and services offered on our Site, Raindrop may collect personally identifiable information, such as your:',
    ParaPonitOne: 'First and Last Name',
    ParaPonitTwo: 'E-mail Address',
    ParaPonitThree: 'Phone Number',
    icon: <FaArrowCircleRight />,
    ParaPonitFour: 'Employer',
    ParaPonitFive: 'Job Title',
    LowerParaTwo:
      'We do not collect any personal information about you unless you voluntarily provide it to us.  However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site.  These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site.  To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us.  We also may gather additional personal or non-personal information in the future.',
  },
]
